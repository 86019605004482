<template>
    <div>
       <!-- Inputs -->
         <transition name="route" mode="out-in">
       <div class="" v-if=!isNextBtn>
              <div class="new-form-element" >
                <p
                  class="m-0 pl-1 "
                >
                  Account Email <span style="color: #969696">*</span>
                </p>
                <div class="NewFormInput d-flex align-items-center w-100">
                  <input
                    type="email m-0"
                    class="flex-grow-1"
                    placeholder="Enter your account email"
                    v-model="userDetail.accountEmail"
                  />
                </div>
              
              <div class="mt-3 w-100 text-center">
                <button
                  :disabled="!validateEmail(userDetail.accountEmail)"
                  @click="checkEmailValidation()"
                  class="btn w-100 NewFormInput-Btn btn-padding"
                >
              
                   <span v-if="!SignUpSpinner">Next</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
                  </div>
                </div>
               <div v-else>
              <div class="new-form-element mt-3 ">
                <p class="m-0 pl-1">
                  Business name <span style="color: #969696">*</span>
                </p>
                <div class="NewFormInput d-flex align-items-center w-100">
                  <input
                    type="text"
                    class="flex-grow-1 m-0"
                    placeholder="Enter your Business name"
                    v-model="userDetail.businessName"
                  />
                </div>
              </div>
              
             
              <div class="new-form-element mt-3 ">
                <p class="m-0 pl-1">
                  Create a password <span style="color: #969696">*</span>
                </p>
                <div class="NewFormInput d-flex align-items-center justify-content-between w-100">
                  <input
                    id="Sign-up-pass-input"
                    :type="passwordFieldType"
                    class="flex-grow-1 m-0"
                    placeholder="Enter your password"
                    v-model="userDetail.password"
                  />
  
                  <div
                    style="cursor: pointer"
                    class=""
                    v-if="passwordFieldType == 'text'"
                  >
                    <b-icon-eye style="color: #d1d1d1" @click="switchPass" />
                  </div>
                  <div
                    style="cursor: pointer"
                    class=""
                    v-else
                    @click="switchPass"
                  >
                    <b-icon-eye-slash style="color: #d1d1d1" />
                  </div>
                </div>
  
                <div>
                  <b-tooltip
                    id="tooltipSignup"
                    target="Sign-up-pass-input"
                    ref="passTooltip"
                    triggers="focus"
                    placement="topleft"
                  >
                    <div class="row" style="font-size: 14px">
                      <div class="col-12">
                        <div class="d-flex align-items-center">
                          <div style="flex: 0.1" class="mr-1">
                            <div v-if="isLengthValid" class="text-success">
                              <b-icon-check-circle-fill
                                class=""
                              ></b-icon-check-circle-fill>
                            </div>
                            <div v-else class="text-danger">
                              <b-icon-x-circle-fill
                                class=""
                              ></b-icon-x-circle-fill>
                            </div>
                          </div>
                          <div class="" style="flex: 0.9; text-align: start">
                            <div v-if="isLengthValid" class="text-success">
                              Eight character long.
                            </div>
                            <div v-else class="text-danger">
                              Eight character long.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex align-items-center">
                          <div style="flex: 0.1" class="mr-1">
                            <div v-if="isLowercaseValid" class="text-success">
                              <b-icon-check-circle-fill
                                class=""
                              ></b-icon-check-circle-fill>
                            </div>
                            <div v-else class="text-danger">
                              <b-icon-x-circle-fill
                                class=""
                              ></b-icon-x-circle-fill>
                            </div>
                          </div>
                          <div class="" style="flex: 0.9; text-align: start">
                            <div v-if="isLowercaseValid" class="text-success">
                              One lowercase letter
                            </div>
                            <div v-else class="text-danger">
                              One lowercase letter
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex align-items-center">
                          <div style="flex: 0.1" class="mr-1">
                            <div v-if="isUppercaseValid" class="text-success">
                              <b-icon-check-circle-fill
                                class=""
                              ></b-icon-check-circle-fill>
                            </div>
                            <div v-else class="text-danger">
                              <b-icon-x-circle-fill
                                class=""
                              ></b-icon-x-circle-fill>
                            </div>
                          </div>
                          <div class="" style="flex: 0.9; text-align: start">
                            <div v-if="isUppercaseValid" class="text-success">
                              One uppercase letter.
                            </div>
                            <div v-else class="text-danger">
                              One uppercase letter.
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex align-items-center">
                          <div style="flex: 0.1" class="mr-1">
                            <div v-if="isNumberValid" class="text-success">
                              <b-icon-check-circle-fill
                                class=""
                              ></b-icon-check-circle-fill>
                            </div>
                            <div v-else class="text-danger">
                              <b-icon-x-circle-fill
                                class=""
                              ></b-icon-x-circle-fill>
                            </div>
                          </div>
                          <div class="" style="flex: 0.9; text-align: start">
                            <div v-if="isNumberValid" class="text-success">
                              One number.
                            </div>
                            <div v-else class="text-danger">One number.</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex align-items-center">
                          <div style="flex: 0.1" class="mr-1">
                            <div
                              v-if="isSpecialCharacterValid"
                              class="text-success"
                            >
                              <b-icon-check-circle-fill
                                class=""
                              ></b-icon-check-circle-fill>
                            </div>
                            <div v-else class="text-danger">
                              <b-icon-x-circle-fill
                                class=""
                              ></b-icon-x-circle-fill>
                            </div>
                          </div>
                          <div class="" style="flex: 0.9; text-align: start">
                            <div
                              v-if="isSpecialCharacterValid"
                              class="text-success"
                            >
                              One special character.
                            </div>
                            <div v-else class="text-danger">
                              One special character.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </b-tooltip>
                </div>
  
                <div class="m-0 pl-1 mt-1" v-if="userDetail.password != ''">
                  <div class="text-danger" v-if="!isPasswordValid">
                    <span> Invalid Password</span>
                  </div>
                  <span class="text-success" v-else> Password is valid! </span>
                </div>
              </div>
  
              <div class="new-form-element mt-2 ">
                <b-form-checkbox
                  id="NewSignUpCheckbox"
                  v-model="userDetail.agreeTerms"
                  name="NewSignUpCheckbox"
                  class="SignUpCheckbox"
                  :value="true"
                  :unchecked-value="false"
                >
                  <div class="m-0">
                    I agree with the
                    <button class="btn m-0 p-0" @click.stop="openTC">
                      Terms & Conditions
                    </button>
                  </div>
                </b-form-checkbox>
              </div>
  
              <div class="mt-2 d-flex justify-content-center  w-100">
                <!-- <recaptcha /> -->
                <vue-recaptcha
      :sitekey="captchaSiteKey"

      :load-recaptcha-script="true"
      
      ref="recaptcha"
      @verify="onCaptchaVerified"
      @expired="onCaptchaExpired">
    </vue-recaptcha>
              </div>
              <div class="row">
                <div class="col-12 col-md-4">
                   <div class="mt-3 w-100">
                <button
                  
                  @click="isNextBtn=false"
                  class="btn w-100 NewFormInput-q btn-padding"
                >
                  <span > Back</span>
                </button>
              </div>
                </div>
                 <div class="col-12 col-md-8">
                   <div class="mt-3 w-100">
                <button
                  :disabled="!isSignUpValid"
                  @click="SignUp"
                  class="btn w-100 NewFormInput-Btn btn-padding"
                >
                  <span v-if="!SignUpSpinner">Sign Up</span>
                  <div
                    v-else
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
                 </div>
              </div>
             
            
  
   
            </div>
</transition>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import VueRecaptcha from 'vue-recaptcha';
  import {
    BIconEyeSlash,
    BIconEye,
    BIconCheckCircleFill,
    BIconXCircleFill,
  } from "bootstrap-vue";
//  import LoaderComp from "../customize/component/LoaderComp.vue";
  export default {
    
    components: {
      BIconEyeSlash,
      BIconEye,
      BIconCheckCircleFill,
      BIconXCircleFill,
      VueRecaptcha
    //   LoaderComp,
    },
  
    data() {
      return {
        userDetail: {
          accountEmail: "",
          businessName: "",
          password: "",
          agreeTerms: false,
        },
        isNextBtn:false,
        passwordFieldType: "password",
        SignUpSpinner: false,
        isLoading: false,
        captchaSiteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY,
        captchaValue: null,
      };
    },
    // created() {
    //   if (process.client) {
    //     this.isLoading = true;
    //     let user_access_token = localStorage.getItem("user_access_token");
    //     if (user_access_token !== null) {
    //       window.open("/", "_self");
    //     }
  
    //     this.isLoading = false;
    //   }
    // },
  //   mounted() {
  //   this.loadRecaptchaScript();
  // },
  //   beforeDestroy() {
  //   this.removeRecaptchaScript();
  // },
    methods: {
    //   removeRecaptchaScript() {
    //   const scripts = document.head.getElementsByTagName('script');
    //   for (let i = 0; i < scripts.length; i++) {
    //     if (scripts[i].src.includes('google.com/recaptcha/api.js')) {
    //       document.head.removeChild(scripts[i]);
    //       break;
    //     }
    //   }
    // },
    //   loadRecaptchaScript() {
    //   const script = document.createElement('script');
    //   script.src = 'https://www.google.com/recaptcha/api.js';
    //   script.async = true;
    //   script.defer = true;
    //   document.head.appendChild(script);
      
    //   // Optional: Handle script load/error events
    //   script.onload = () => console.log('reCAPTCHA script loaded');
    //   script.onerror = () => console.error('Failed to load reCAPTCHA script');
    // },
      validateEmail(email) {
        /* eslint-disable-next-line */
        let pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return pattern.test(email);
      },
      onOpen() {
        this.$refs.passTooltip.$emit("open");
      },
      onClose() {
        this.$refs.passTooltip.$emit("close");
      },
      switchPass() {
        this.passwordFieldType =
          this.passwordFieldType === "password" ? "text" : "password";
      },
      openTC() {
       
          window.open("https://www.quizell.com/terms-and-condition", "_blank");
        
      },
      onCaptchaVerified(response) {
      this.captchaValue = response
      // Handle the verification success
    },
    onCaptchaExpired() {
      this.$toasted.show("Captcha is expired. Please Try again!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
    },
      async checkEmailValidation(){
        this.SignUpSpinner = true;
            if(this.validateEmail(this.userDetail.accountEmail))
            await axios.post(`/email/exist`, {email:this.userDetail.accountEmail}).then(response=>{
          if(!response.data.data.email_exist)
          this.isNextBtn=true;
        else
         this.$toasted.show('Email already exists', {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 4000,
                });
          }).catch(()=>{
          
             this.$toasted.show(' Email already exists', {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 4000,
                });
              
          }).finally(()=>{
             this.SignUpSpinner = false;
          })
          },
      async SignUp() {
        let isEmailValid = this.validateEmail(this.userDetail.accountEmail);
        if (isEmailValid) {
          this.SignUpSpinner = true;
         try{
           
            let data = {
              email: this.userDetail.accountEmail,
              password: this.userDetail.password,
              name: this.userDetail.businessName,
              "g-recaptcha-response": this.captchaValue,
            };
            const response = await axios.post(`/register`, data);
            await this.$refs.recaptcha.reset();
            if (response.status == 200 && response.data.status == "fail") {
              const Error = response.data.data;
              if (typeof Error == "object") {
                for (const key in Error) {
                  this.$toasted.show(`${Error[key]}`, {
                    theme: "toasted-primary",
                    type: "error",
                    position: "bottom-center",
                    duration: 2000,
                  });
                }
              } else if (typeof Error == "string") {
                this.$toasted.show(`${Error}`, {
                  theme: "toasted-primary",
                  type: "error",
                  position: "bottom-center",
                  duration: 2000,
                });
              } else {
                this.$toasted.show("Error occured", {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 4000,
                });
              }
            } else {
              localStorage.setItem(
                "user_access_token",
                response.data.access_token
              );
              this.$router.push("/email-verify");
            }
          
         
        this.SignUpSpinner = false;
        } catch(e){

          if(e){
            this.$toasted.show("Error Occured. Please try again!", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
          throw e
          }
        } finally{
          this.SignUpSpinner = false;
        }
         
    }else {
          this.$toasted.show("Email is not valid.", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 4000,
          });
        }
      },
      
    },
    computed: {
      // && this.captchaValue !== null
      isSignUpValid() {
        return (
          this.userDetail.accountEmail !== "" &&
          this.userDetail.password !== "" &&
          this.userDetail.businessName !== "" &&
          this.userDetail.agreeTerms &&
          this.isPasswordValid
        );
      },
      isPasswordValid() {
        const passwordRegex = new RegExp(
          /^(?=^.{8,}$)(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/
        );
        return passwordRegex.test(this.userDetail.password) ? true : false;
      },
      isLengthValid() {
        const passwordRegex = new RegExp(/^(?=^.{8,}$).*$/);
        return passwordRegex.test(this.userDetail.password) ? true : false;
      },
      isUppercaseValid() {
        const passwordRegex = new RegExp(/^(?=.*?[A-Z]).*$/);
        return passwordRegex.test(this.userDetail.password) ? true : false;
      },
      isLowercaseValid() {
        const passwordRegex = new RegExp(/^(?=.*?[a-z]).*$/);
        return passwordRegex.test(this.userDetail.password) ? true : false;
      },
      isNumberValid() {
        const passwordRegex = new RegExp(/^(?=.*?[0-9]).*$/);
        return passwordRegex.test(this.userDetail.password) ? true : false;
      },
      isSpecialCharacterValid() {
        const passwordRegex = new RegExp(/^(?=.*?[#?!@$%^&*-]).*$/);
        return passwordRegex.test(this.userDetail.password) ? true : false;
      },
      userPass() {
        return this.userDetail.password;
      },
    },
    watch: {
  isNextBtn(newValue)
  {
    this.$emit('hideApps',newValue)
  },
      userPass() {
        if (this.isPasswordValid) {
          this.onClose();
        } else {
          this.onOpen();
        }
      },
    },
   
  };
  </script>
  <style>
  .btn-padding{
    padding: 14px 24px;
  }
.route-enter-from {
opacity: 0;
transform: translateX(100px)
}
.route-enter-active {
transition: ass 0.3s ease-out;
}
.route-leave-to {
opacity: 0;
transform: translateX(-100px)}
.route-leave-active{
transition: all 0.3s ease-in;}
</style>
  <style scoped>
 .route-enter-from {
opacity: 0;
transform: translateX(100px)
}
.route-enter-active {
transition: ass 0.3s ease-out;
}
.route-leave-to {
opacity: 0;
transform: translateX(-100px)}
.route-leave-active{
transition: all 0.3s ease-in;}
  
  .SignUpCheckbox p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 2px !important;
    color: #161e34;
  }
  .SignUpCheckbox button {
    text-decoration: none;
    color: #4d1b7e;
    font-weight: 500;
  }
  .NewFormInput-q {
    background: #ffffff;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    border: 1px solid #4d1b7e;
    text-align: center;
    color: #4d1b7e;
  }
  .NewFormInput-Btn {
    background: #4d1b7e;
    border-radius: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
  }

  .NewFormInput-q:hover{
    background: #4d1b7e;
    color: #ffffff;
  }
  .NewFormInput-Btn:hover {
    background: #ffc000;
    color: #ffffff;
  }
  .NewFormInput-Btn:disabled:hover {
    background: #4d1b7e !important;
  }
  
  .MainSection .register {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  
    color: #161e34;
  }
  .MainSection .register button {
    color: #4d1b7e;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  
.new-form-element{
  color: #161E34;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
  
  #tooltipSignup >>> .tooltip-inner {
    min-width: 200px;
    opacity: 1;
    border: 1px solid #000000;
    background-color: #ffffff !important;
  }
  </style>
  